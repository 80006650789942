import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../services/Main/Api'
import {
    Alert,
    Avatar,
    Backdrop,
    Box, Button,
    Card,
    CardContent, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { useSnackbar } from 'notistack'
import Context from '../../../contexts/Context'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment/moment'
import {
    ContactMailOutlined, PriorityHigh,
} from '@mui/icons-material'
import Masks from '../../../components/Shared/Masks'
import useClipboard from '../../../hooks/useClipboard'
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

const CompanyDetail = () => {
    const { filters, setFilters, loadingModal, setLoadingModal } = useContext(Context)

    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [reqData, setReqData] = useState()
    const [reqDataBank, setReqDataBank] = useState()
    const [isSaving, setIsSaving] = useState(false)
    const [open, setOpen] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function cancelAccountBank() {
        try {
            const dataReq = {
                ...reqDataBank,
            };

            await api.put(`/fitbank/${params.id}/`, dataReq);
            enqueueSnackbar('Conta cancelada com sucesso!', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar(error.response.data, { variant: 'error' });
            console.log(error);
        }
    }



    const handleConfirm = () => {
        cancelAccountBank();
        setOpen(false);
    };


    const getcompanyId = async () => {
        setIsLoading(true)
        setIsSaving(true)
        const url = `condo/${params.id}/`
        let result = await api.get(url).then(response => {
            console.log(response.data)
            setReqData(response.data)
            setIsLoading(false)
            setIsSaving(false)
        })
    }


    const getAccountBankCompany = async () => {
        setIsLoading(true)
        setIsSaving(true)
        const url = `fitbank/${params.id}/`
        try {
            let result = await api.get(url).then(response => {
                console.log(response.data)
                setReqDataBank(response.data)
                setIsLoading(false)
                setIsSaving(false)
            })
        } catch (error) {
            console.log(error)
            enqueueSnackbar("Não foi possível obter informações da conta.", { variant: 'error' });
            setIsLoading(false)
            setIsSaving(false)
        }
    }
    useEffect(() => {

        getcompanyId();
        getAccountBankCompany();
        window.scrollTo(0, 0)


    }, [])

    const formatCep = (cep) => {
        const match = cep.match(/^(\d{2})(\d{3})(\d{3})$/);
        if (match) {
            return `${match[1]}.${match[2]}-${match[3]}`;
        }
        return cep;
    };

    console.log('reqDataBank', reqDataBank)


    return (
        <>

            <Helmet>
                <title>Invoice - Bloco B3</title>
            </Helmet>
            <Backdrop
                sx={{ color: '#fff', zIndex: 1500, }}
                open={loadingModal}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            {/*{!isLoading &&*/}
            { reqData &&
                <Grid
                    container
                    spacing={2}
                    justifyContent={'space-between'}
                    sx={{ flexGrow: 1, padding: "1rem"  }}
                >

                    <Grid md={8}>
                        <Card
                            sx={{padding:"1rem", margin: "1rem" }}>
                            { !isLoading &&
                                <>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={2}
                                            justifyContent={'space-between'}
                                            sx={{flexGrow: 1}}
                                        >
                                            <Grid xs={6}>
                                                <Box>
                                                    <Typography variant="h6" sx={{
                                                        color: 'primary.main'
                                                    }}>Dados da Empresa</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box
                                                    border
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                        alignItems: 'center'

                                                    }}
                                                >

                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider/>
                                    <Box display="flex">
                                        <Grid xs={7}>
                                        <Box marginTop={'2rem'} marginRight={'2rem'} justifyContent="space-between">

                                            <SimpleTypography
                                                label={'Status:'}
                                                directValue={reqData?.status}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Razão Social:'}
                                                directValue={reqData?.legal_name}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Cnpj/Cpf:'}
                                                directValue={reqData?.cnpj?.length <= 14 ? Masks.cpf(reqData?.cnpj ?? '') : Masks.cnpj(reqData?.cnpj ?? '')}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Nome da Empresa:'}
                                                directValue={reqData?.name}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />


                                            <SimpleTypography
                                                label={'Telefone:'}
                                                directValue={reqData?.phone ? Masks?.phone(reqData?.phone): ''}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'E-mail:'}
                                                directValue={reqData?.email}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Endereço:'}
                                                directValue={`${reqData?.street}, ${reqData?.number}  ${reqData?.complement ? reqData?.complement : ''}`}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Bairro:'}
                                                directValue={reqData?.district}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Cidade:'}
                                                directValue={`${reqData?.city} - ${reqData.state}`}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Cep:'}
                                                directValue={formatCep(reqData?.cep)}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Tipo da Empresa:'}
                                                directValue={reqData?.type}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Modo de Referencia:'}
                                                directValue={reqData?.reference_mode_company}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Permitir Whatsapp:'}
                                                directValue={reqData?.whatsapp_send_message ? 'Sim' : 'Não'}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Nome no Whatsapp:'}
                                                directValue={reqData?.whatsapp_name ? reqData?.whatsapp_name : '-'}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />


                                        </Box>
                                        </Grid>
                                        <Grid xs={5}>
                                        <Box marginTop={'2rem'} justifyContent="space-between">

                                            <SimpleTypography
                                                label={'Tipo da Empresa:'}
                                                directValue={reqData?.type_description}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Plano:'}
                                                directValue={reqData?.type_plan}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Bonificação:'}
                                                directValue={reqData?.bonus ? 'Sim' : 'Não'}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Preço do Plano:'}
                                                directValue={Masks.money(reqData?.plan_price.toString())}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Preço Ted:'}
                                                directValue={Masks.money(reqData?.ted_fee.toString())}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Preço Pix:'}
                                                directValue={Masks.money(reqData?.pix_fee.toString())}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Debito Automático:'}
                                                directValue={reqData?.auto_debit ? 'Sim' : 'Não'}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Método de Pagamento:'}
                                                directValue={reqData.payment_method}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Preço por Cobrança:'}
                                                directValue={reqData.price_per_charge}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Preço por Fatura:'}
                                                directValue={reqData.price_per_invoice}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Número da Prévia do Contrato:'}
                                                directValue={reqData.preview_of_number_contract}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                        <SimpleTypography
                                            label={'Data de Início:'}
                                            directValue={moment(reqData?.created_at).format('DD/MM/YYYY')}
                                            labelStyles={{
                                                fontWeight: 'bold',
                                                marginRight: '1rem'
                                            }}
                                            boxStyles={{
                                                width: '100%',
                                                mb: '1rem'
                                            }}
                                        />
                                            <SimpleTypography
                                                label={'Data da última atualização:'}
                                                directValue={moment(reqData.updated_at).format('DD/MM/YYYY')}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />

                                            <SimpleTypography
                                                label={'Preço do Whatsapp:'}
                                                directValue={reqData?.whatsapp_fee ? Masks.money(reqData.whatsapp_fee.toString()) : ''}
                                                labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                                boxStyles={{ width: '100%', mb: '1rem' }}
                                            />


                                        </Box>
                                        </Grid>
                                    </Box>



                                </>
                            }

                        </Card>

                    </Grid>

                    <Grid md={4}>
                        <Card sx={{padding:"1rem", margin: "1rem" }}>
                            <CardContent>
                                <Box sx={{display: "flex", justifyContent: 'space-between'}}>
                                <Box sx={{display: "flex", alignItems: "center", padding: ".5rem 0 "}}>
                                    <AccountBalanceOutlinedIcon sx={{
                                        color: 'primary.main'
                                    }} />
                                    <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                                        Contas Bancárias</Typography>
                                </Box>
                                    { reqDataBank?.close_account_status?.key &&
                                <Box sx={{display: "flex", alignItems: "center", padding: ".5rem 0 "}}>
                                    <Typography  sx={{marginRight: ".5rem"}} variant="p">
                                        Encerramento:
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'end',
                                            // justifyContent: 'start'
                                        }}
                                    >

                                        <Chip
                                            color={reqDataBank?.close_account_status?.key === '1' ? 'secondary' :
                                                reqDataBank?.close_account_status?.key === '3' ? 'warning' :
                                                reqDataBank?.close_account_status?.key === '4' ? 'error' :
                                                reqDataBank?.close_account_status?.key === '5' ? 'success' :
                                                    reqDataBank?.close_account_status?.key === '2' ? 'error' : 'info'
                                            }
                                            size="small"
                                            variant="outlined"
                                            label={reqDataBank?.close_account_status?.value}/>


                                    </Box>
                                </Box>
                                    }
                                </Box>
                                <Divider/>

                                {reqDataBank ? reqDataBank &&
                                <Box marginTop={'2rem'} justifyContent="space-between">

                                    <SimpleTypography
                                        label={'Banco:'}
                                        directValue={reqDataBank?.bank ? reqDataBank?.bank : '-'}
                                        labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                        boxStyles={{ width: '100%', mb: '1rem' }}
                                    />

                                    <SimpleTypography
                                        label={'Agencia:'}
                                        directValue={reqDataBank?.bank_branch ? reqDataBank?.bank_branch : '-'}
                                        labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                        boxStyles={{ width: '100%', mb: '1rem' }}
                                    />


                                    <SimpleTypography
                                        label={'Conta:'}
                                        directValue={`${reqDataBank?.bank_account ? reqDataBank?.bank_account : '-'} - ${reqDataBank?.bank_account_digit ? reqDataBank?.bank_account_digit : '-'}`}
                                        labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                        boxStyles={{ width: '100%', mb: '1rem' }}
                                    />

                                    <SimpleTypography
                                        label={'Spb Banco:'}
                                        directValue={reqDataBank?.spb_bank ? reqDataBank?.spb_bank : '-'}
                                        labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                        boxStyles={{ width: '100%', mb: '1rem' }}
                                    />

                                    <SimpleTypography
                                        label={'Spb Agencia:'}
                                        directValue={reqDataBank?.spb_bank_branch ? reqDataBank?.spb_bank_branch : '-'}
                                        labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                        boxStyles={{ width: '100%', mb: '1rem' }}
                                    />


                                    <SimpleTypography
                                        label={'Spb Conta:'}
                                        directValue={`${reqDataBank?.spb_bank_account ? reqDataBank.spb_bank_account : '-'} - ${reqDataBank?.spb_bank_account_digit ? reqDataBank?.spb_bank_account_digit : '-'}`}
                                        labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                        boxStyles={{ width: '100%', mb: '1rem' }}
                                    />

                                    <SimpleTypography
                                        label={'Chave da Conta:'}
                                        directValue={reqDataBank?.account_key ? reqDataBank.account_key : '-'}
                                        labelStyles={{ fontWeight: 'bold', marginRight: '1rem' }}
                                        boxStyles={{ width: '100%', mb: '1rem' }}
                                    />


                                </Box>
                                    :
                                    <Box>
                                        <Typography  sx={{marginRight: ".5rem", textAlign: "center",}} variant="h6">
                                            Conta Inexistente
                                        </Typography>
                                    </Box>
                                }
                            </CardContent>
                            <Divider/>
                            { reqDataBank &&
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                                <Button
                                    size={'small'}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClickOpen}
                                    disabled={["0", "1", "3", "5"].includes(reqDataBank?.close_account_status?.key)}
                                >
                                    Encerrar Conta
                                </Button>
                            </Box>
                            }

                        </Card>

                    </Grid>


                </Grid>}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            sx={{
                                bgcolor: "primary.main",
                                mr: '1rem'
                            }}
                        >
                            <PriorityHigh />
                        </Avatar>
                        {"Confirmação de Encerramento"}
                    </Box>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Alert severity={'warning'} sx={{mb:2}}>
                            Ao confirmar o encerramento esta conta será fechada no FitBank, não podendo ser revertido.
                        </Alert>
                        Você tem certeza de que deseja encerrar a conta?
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <Button size={'small'} onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button size={'small'} onClick={handleConfirm} variant="contained" color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isSaving}
                // onClick={() => {
                //   if (isSaving === false){
                //     setActiveBackdrop(false)
                //   }
                //
                // }}
            >
                <CircularProgress color="inherit" />

            </Backdrop>
        </>

    )
}

export default CompanyDetail
