import {Box, Chip, Grid, IconButton, Link, Tooltip} from '@mui/material'
import InvoiceDetailTitle from "./dependencies/InvoiceDetailTitle";
import InvoiceDetailStatus from "./dependencies/InvoiceDetailStatus";
import InvoiceDetailDue from "./dependencies/InvoiceDetailDue";
import InvoiceDetailDownload from "./dependencies/InvoiceDetailDownload";
import InvoiceDetailSentMail from "./dependencies/InvoiceDetailSentMail";
import Divider from "@mui/material/Divider";
import React, {useContext, useState} from "react";
import InvoiceDetailGenerateSlip from "./dependencies/InvoiceDetailGenerateSlip";
import InvoiceDetailDeleteSlip from "./dependencies/InvoiceDetailDeleteSlip";
import InvoiceDetailDownloadRentReceipt from "./dependencies/InvoiceDetailDowloadRentReceipt";
import {NoteAdd, OpenInNew} from '@mui/icons-material'
import Context from "../../../../../../contexts/Context";
import MakeBankSlip from "../../MakeItPaidMenu/dependencies/MakeBankSlip";
import {useSnackbar} from "notistack";
import {api} from "../../../../../../services/Main/Api";

const InvoiceDetailHeader = ({ invoice, sending, sendToMail, condo }) => {
    const { user } = useContext(Context)
    const { perm_bank } = user || {}
    const {setLoadingModal} = useContext(Context)
    const [isOpen, setIsOpen] = useState(false)
    const [openByStatus, setOpenByStatus] = useState({paid: false, remake: false, create: false, open: false, deleted: false})
    const [reqNewInvoice, setReqNewInvoice] = useState({ send_email: false, send_whatsapp: false, due_date: ''})
    const [reqCreateInvoice, setReqCreateInvoice] = useState({ send_email: false, send_whatsapp: false, due_date: ''})
    const [errors, setErrors] = useState(null)
    const [anchor, setAnchor] = useState(null)
    const {setInvoiceListRefresh} = useContext(Context)
    const {enqueueSnackbar} = useSnackbar()

    const handleCloseConfirmation = (e) => {
        setOpenByStatus({paid: false, remake: false, create: false, open: false, deleted: false})
        setIsOpen(false)
    }

    const generate = () => {
        setLoadingModal(true)
        api.post(`bank-slip/invoice/${invoice?.id}/`, reqCreateInvoice)
            .then(response =>{
                setLoadingModal(false)
                enqueueSnackbar("Pedido para gerar boleto efetuado!", {variant: 'success'})
                enqueueSnackbar("Processando boleto, disponivel em breve!", {variant: 'success'})
            }).catch(response => {
            enqueueSnackbar("Informe uma data válida", {variant: "error"})
            setErrors(response)
            console.log(response.response.data)
            setLoadingModal(false)
        }).finally(() => {
            setInvoiceListRefresh(true)
        })
    }



    return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
      >
        <InvoiceDetailTitle
          invoice={invoice}
        />
        <InvoiceDetailStatus
          invoice={invoice}
        />
        <Grid
          item
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          display={'flex'}
          textAlign={'right'}
        >
          <InvoiceDetailDue
            invoice={invoice}
          />
            { perm_bank === true &&
          <Box>
            {/*<InvoiceDetailDownload*/}
            {/*  invoice={invoice}*/}
            {/*/>*/}
            { invoice?.status.key !== 'open' && invoice?.status.key !== 'processing' &&
            <Link
              href={invoice?.payment_page}
              target="_blank"
              onClick={(e) => e.stopPropagation()}>
              <Chip
                label={'Página de pagamento'}
                size={'small'}
                icon={<OpenInNew />}
              />
            </Link> }
            <InvoiceDetailSentMail
              invoice={invoice}
              sending={sending}
              sendToMail={sendToMail}
            />
            {!invoice?.bank_slip_status?.key || invoice.bank_slip_status.key === 7 ?
                <Tooltip
                    title={ 'Gerar Boleto' }
                    arrow
                    placement={'top'}
                >
        <span>
          <IconButton
              disabled={invoice?.status?.key !== 'open'}
              target={'_blank'}
              onClick={(event) => {
                  event.stopPropagation();
                  setOpenByStatus((prevState) => ({ ...prevState, create: true }));
              }}
          >
            <NoteAdd/>
          </IconButton>
        </span>
                    <MakeBankSlip
                        openByStatus={openByStatus}
                        setOpenByStatus={setOpenByStatus}
                        reqCreateInvoice={reqCreateInvoice}
                        setReqCreateInvoice={setReqCreateInvoice}
                        handleCloseConfirmation={handleCloseConfirmation}
                        generate={generate}
                        invoice={invoice}
                    />
                </Tooltip>
                :
                <InvoiceDetailDeleteSlip
                  invoice={invoice}
                />
            }
              {condo?.type === "RENT" && invoice?.status?.key === 'paid' &&
                  <InvoiceDetailDownloadRentReceipt
                      invoice={invoice}
                  />
              }

          </Box>
            }
        </Grid>
      </Grid>
      <Divider
        sx={{
          mb: '2rem',
          mt: '.5rem'
        }}
      />
    </>
  )
}

export default InvoiceDetailHeader