import React, {useContext, useEffect, useState} from 'react'
import {
    Backdrop,
    Box,
    Card,
    Chip,
    Container,
    Grid, InputAdornment,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TableSortLabel, TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import {api} from '../../../services/Main/Api'
import moment from 'moment'
import {Helmet} from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'
import Context from '../../../contexts/Context'

import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'
import {Alarm, Search} from '@mui/icons-material'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import {getCondoId} from "../../../services/Main/AuthStorage";
import SignedRecipientTransferMakeMenu from "./SignedRecipientTransferMakeMenu";

const SignedRecipientTransferList = () => {
    const navigate = useNavigate()
    const [response, setResponse] = useState(null)
    const [monthInput, setMonthInput] = useState(moment().format("MM"))
    const [yearInput, setYearInput] = useState(moment().format("YYYY"))
    const [loading, setLoading] = useState(false)
    const [isPaid, setIsPaid] = useState(false)
    const [search, setSearch] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [updateList, setUpdateList] = useState(false)
    const [filters, setFilters] = useState(
        {
            condo_id: getCondoId(),
            page: 1,
            page_size: 50,
        }
    )
    const monthFormat = moment().format("MM")
    const yearFormat = moment().format("YYYY")

    const reference = response?.results && response?.results.length > 0 ?
        response?.results[0].reference_mode_company.value : null;

    function handleManualPayment() {
        setIsPaid(true);
        setLoading(true)
        setUpdateList(!updateList);
    }


    useEffect(() => {
        let params = {...searchParams, "month": monthInput, "year": yearInput,}
        if (search) {
            params.search = search
        } else {
            delete params.search
        }
        setSearchParams(params)
    }, [monthInput, yearInput, search])

    function getData() {
        setLoading(true)
        let month = monthFormat
        let year = yearFormat

        if (searchParams.get("month") && searchParams.get("year")) {
            month = searchParams.get("month")
            year = searchParams.get("year")
        }

        let filter = {...filters, month: month, year: year}
        if (searchParams.get("rent_contract_id")) {
            filter = {...filter, rent_contract_id: searchParams.get("rent_contract_id")}
        }

        if (searchParams.get("invoice_id")) {
            filter = {...filter, invoice_id: searchParams.get("invoice_id")}
        }

        if (searchParams.get("search")) {
            filter = {...filter, search: searchParams.get("search")}
        }

        api.get(`signed-recipient-transfer/`, {params: filter})
            .then(response => {
                const data = response.data
                setResponse(data)
                setLoading(false)
            }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (searchParams.get("month") && searchParams.get("year")) {
            setMonthInput(searchParams.get("month"))
            setYearInput(searchParams.get("year"))
        } else {
            getData()
        }
    }, [])

    useEffect(() => {
        getData()
    }, [searchParams])

    useEffect(function whenPageLoad() {
        getData()
    }, [filters])

    useEffect(() => {
        getData();
    }, [updateList]);

    const handleFilter = (asc: string, desc: string) => {
        setFilters(prevState => ({
                ...prevState,
                ordering: filters?.ordering === asc ? desc : asc
            })
        )
    }

    let typingTimer = ""

    return (
        <>
            <Helmet>
                <title>Repasses - Bloco B3</title>
            </Helmet>

            <Backdrop
                sx={{color: '#fff', zIndex: 1500,}}
                open={loading}
            >

                <CircularProgress color="inherit"/>
            </Backdrop>
            <Container maxWidth={false}>
                <Box
                    sx={{
                        display: 'flex',
                        '@media (max-width:600px)': {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            gap: 3,
                        },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 3,

                    }}
                >
                    <Typography
                        textAlign={'center'}
                        variant={'h5'}
                    >
                        Repasses ({response?.count || 0})
                    </Typography>
                    <Box sx={{textAlign: "right"}}>
                        <TextField id="outlined-basic" label="Buscar por beneficiário" variant="outlined"
                                   sx={{marginRight: ".5rem"}}
                                   onKeyUp={(event) => {
                                       clearTimeout(typingTimer);
                                       typingTimer = setTimeout(() => setSearch(event.target.value), 1000);
                                   }}
                                   size={'small'}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
                                   }}
                        />
                        <DatePicker
                            openTo="month"
                            views={['year', 'month']}
                            label="Mês de Pagamento"
                            inputProps={{readOnly: true}}
                            disableMaskedInput

                            value={moment(`${monthInput}-${yearInput}`, 'MM-YYYY').toDate()}
                            onChange={(newValue) => {
                                setMonthInput(moment(new Date(newValue)).format("MM"))
                                setYearInput(moment(new Date(newValue)).format("YYYY"))
                            }}
                            slotProps={{
                                field: {
                                    clearable: false, onClear: () => {
                                    }
                                },
                                textField: {
                                    size: "small"
                                }
                            }}
                        />
                    </Box>
                </Box>


                <Card>
                    {response?.results ?
                        <>

                            <Grid container sx={{p: 2, alignItems: 'center'}} spacing={1}>
                                <Grid item xs={12} sm={4} lg={3}>
                                    {/*<UnitFilter />*/}
                                </Grid>
                                <Grid item xs={12} sm={4} lg={3}>
                                    {/*<ReferenceMonthFilter*/}
                                    {/*  response={response}*/}
                                    {/*/>*/}
                                </Grid>
                            </Grid>

                            <TableContainer>
                                {response?.results?.length > 0 ?
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <TableSortLabel
                                                        style={{fontSize: '14px'}}
                                                        active={filters?.ordering === '-id' || filters?.ordering === 'id'}
                                                        direction={filters?.ordering === '-id' ? 'desc' : 'asc'}
                                                        onClick={() => handleFilter('id', '-id')}
                                                    >#
                                                    </TableSortLabel>
                                                </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            style={{fontSize: '14px'}}
                                                            active={filters?.ordering === '-signed_recipient' || filters?.ordering === 'signed_recipient'}
                                                            direction={filters?.ordering === '-signed_recipient' ? 'desc' : 'asc'}
                                                            onClick={() => handleFilter('signed_recipient', '-signed_recipient')}
                                                        >
                                                            Beneficiário
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    {reference === 'payment' ?
                                                        <TableCell>
                                                            <TableSortLabel
                                                                style={{fontSize: '14px'}}
                                                                active={filters?.ordering === '-reference_date' || filters?.ordering === 'reference_date'}
                                                                direction={filters?.ordering === '-reference_date' ? 'desc' : 'asc'}
                                                                onClick={() => handleFilter('reference_date', '-reference_date')}
                                                            >
                                                                Competência
                                                            </TableSortLabel>
                                                        </TableCell>

                                                        :
                                                        <TableCell>
                                                            <TableSortLabel
                                                                style={{fontSize: '14px'}}
                                                                active={filters?.ordering === '-reference_date' || filters?.ordering === 'reference_date'}
                                                                direction={filters?.ordering === '-reference_date' ? 'desc' : 'asc'}
                                                                onClick={() => handleFilter('reference_date', '-reference_date')}
                                                            >Referência
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    }
                                                    <TableCell>
                                                        <TableSortLabel
                                                            style={{fontSize: '14px'}}
                                                            active={filters?.ordering === '-unit__name' || filters?.ordering === 'unit__name'}
                                                            direction={filters?.ordering === '-unit__name' ? 'desc' : 'asc'}
                                                            onClick={() => handleFilter('unit__name', '-unit__name')}
                                                        >Contrato
                                                        </TableSortLabel>
                                                    </TableCell>



                                                    <TableCell>
                                                        <TableSortLabel
                                                            style={{fontSize: '14px'}}
                                                            active={filters?.ordering === '-invoice' || filters?.ordering === 'invoice'}
                                                            direction={filters?.ordering === '-invoice' ? 'desc' : 'asc'}
                                                            onClick={() => handleFilter('invoice', '-invoice')}
                                                        >Fatura
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            style={{fontSize: '14px'}}
                                                            active={filters?.ordering === '-total' || filters?.ordering === 'total'}
                                                            direction={filters?.ordering === '-total' ? 'desc' : 'asc'}
                                                            onClick={() => handleFilter('total', '-total')}
                                                        >Valor
                                                        </TableSortLabel>
                                                    </TableCell>
                                                <TableCell align={"center"}>
                                                    <TableSortLabel
                                                      style={{fontSize: '14px'}}
                                                      active={filters?.ordering === '-auto_transfer_scheduled' || filters?.ordering === 'auto_transfer_scheduled'}
                                                      direction={filters?.ordering === '-auto_transfer_scheduled' ? 'desc' : 'asc'}
                                                      onClick={() => handleFilter('auto_transfer_scheduled', '-auto_transfer_scheduled')}
                                                    >
                                                        Data
                                                    </TableSortLabel>
                                                </TableCell>
                                                    <TableCell align={"center"}>
                                                        <TableSortLabel
                                                            style={{fontSize: '14px'}}
                                                            active={filters?.ordering === '-status' || filters?.ordering === 'status'}
                                                            direction={filters?.ordering === '-status' ? 'desc' : 'asc'}
                                                            onClick={() => handleFilter('status', '-status')}
                                                            >
                                                            Status
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell></TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>


                                            {response.results.map(item =>
                                                <TableRow
                                                    key={item.id}
                                                    hover
                                                    sx={{'cursor': 'pointer'}}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleManualPayment = {handleManualPayment}
                                                        navigate(`/repasses/${item.id}`)
                                                    }}
                                                >
                                                    <TableCell>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {item.number}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {item.name} - {item.percentage}%
                                                        </Box>
                                                    </TableCell>
                                                    {reference === 'payment' ?
                                                        <TableCell>
                                                            {moment(item.reference_date, "YYYY-MM-DD").format("MMM/YYYY")}

                                                        </TableCell>
                                                        :
                                                        <TableCell>
                                                            {moment(item.reference_date, "YYYY-MM-DD").subtract(1, 'month').format("MMM/YYYY")}

                                                        </TableCell>
                                                    }
                                                    <TableCell>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {item.rent_contract.number} - {item.rent_contract.name}
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {item.invoice.number}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                            color={item?.total < 0 && "error.main"}
                                                        >
                                                            {Masks.money(item?.total.toString())}

                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <Box
                                                          sx={{
                                                              display: 'flex',
                                                              alignItems: 'center',
                                                              justifyContent: 'center',
                                                          }}
                                                        >
                                                            {!item.transfered_at && item.auto_transfer_scheduled
                                                              ? moment(item.auto_transfer_scheduled, "YYYY-MM-DD").format("DD/MM/YYYY")
                                                              : item.transfered_at
                                                                ? moment(item.transfered_at, "YYYY-MM-DD").format("DD/MM/YYYY")
                                                                : '---'
                                                            }
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            <Tooltip
                                                                title={item?.status.id === 'PAID' ? `Pago em ${moment(item?.transfered_at).format('DD/MM/YYYY [às] H:mm')}` :
                                                                    item?.status.id === 'ERROR' ? `${item?.status_message}` :
                                                                        item?.status.id === 'DUE' ? `${item?.status_message}` :
                                                                            item?.status.id === 'FINALIZED' ? `${item?.status_message}` :
                                                                                item?.status.id === 'PROCESSING' ? `${item?.status_message}` :
                                                                                    item?.status.id === 'FAILED' ? `${item?.status_message}` :
                                                                                        `Criado em ${moment(item.created_at).format('DD/MM/YYYY [às] H:mm')}`}>
                                                                <Chip
                                                                    color={item?.status.id === 'PAID' ? 'success' :
                                                                        item?.status.id === 'PENDING' ? 'warning' :
                                                                            item?.status.id === 'SCHEDULED' ? 'secondary' :
                                                                                item?.status.id === 'FINALIZED' ? 'success' :
                                                                                    item?.status.id === 'PROCESSING' ? 'primary' :
                                                                                        item?.status.id === 'ERROR' ? 'error' :

                                                                                            item?.status.id === 'DEBIT' ? 'error' :
                                                                                                item?.status.id === 'FAILED' ? 'error' :
                                                                                                    item?.status.id === 'OPEN' && 'info'}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    label={item?.status.value}/>
                                                                <Typography
                                                                    sx={{
                                                                        mt: '0.3rem',
                                                                        ml: '0.3rem',
                                                                        fontSize: '0.7rem',

                                                                    }}
                                                                >
                                                                    {item?.finalized_by === 'MANUAL' && 'Baixa Manual'}
                                                                </Typography>
                                                            </Tooltip>


                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        {(item?.status.id === "OPEN" ||
                                                                item?.status.id === "PENDING" ||
                                                                item?.status.id === "FINALIZED" ||
                                                                item?.status.id === "ERROR") &&
                                                            <SignedRecipientTransferMakeMenu
                                                                rentRecipientTransfer={item}
                                                                handleManualPayment={handleManualPayment}
                                                            />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )}

                                        </TableBody>
                                    </Table>
                                    :
                                    <NoResultsAnimation />
                                }
                            </TableContainer>
                        </>
                        :
                        <Skeleton
                            variant="rounded"
                            height={700}
                            animation="pulse"
                        />
                    }
                </Card>

            </Container>
            {response?.count > 0 &&
            <PaginationGlobal
                state={response}
                filters={filters}
                setFilters={setFilters}
            />
            }
        </>
    )
}

export default SignedRecipientTransferList
