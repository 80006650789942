import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../services/Main/Api'
import {
    Backdrop,
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { useSnackbar } from 'notistack'
import Context from '../../../contexts/Context'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment/moment'
import {
    ContactMailOutlined,
} from '@mui/icons-material'
import Masks from '../../../components/Shared/Masks'
import useClipboard from '../../../hooks/useClipboard'

const CompanyRateDetail = () => {
    const { filters, setFilters, loadingModal, setLoadingModal } = useContext(Context)

    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState()
    const [isSaving, setIsSaving] = useState(false)


    const getCustomerInvoiceId = async () => {
        setIsLoading(true)
        setIsSaving(true)
        const url = `customer-invoice/rate/${params.id}/`
        let result = await api.get(url).then(response => {
            console.log(response.data)
            setData(response.data)
            setIsLoading(false)
            setIsSaving(false)
        })
    }

    useEffect(() => {

        getCustomerInvoiceId();
        window.scrollTo(0, 0)


    }, [])

    return (
        <>

            <Helmet>
                <title>Invoice - Bloco B3</title>
            </Helmet>
            <Backdrop
                sx={{ color: '#fff', zIndex: 1500, }}
                open={loadingModal}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            {/*{!isLoading &&*/}
            { data &&
                <Grid
                    container
                    spacing={2}
                    justifyContent={'space-between'}
                    sx={{ flexGrow: 1, padding: "1rem"  }}
                >

                    <Grid md={8}>
                        <Card
                            sx={{padding:"1rem", margin: "0 1rem " }}>
                            { !isLoading &&
                                <>
                                    <CardContent >
                                        <Grid
                                            container
                                            spacing={2}
                                            justifyContent={'space-between'}
                                            sx={{ flexGrow: 1}}
                                        >
                                            <Grid xs={6}>
                                                <Box>
                                                    <Typography variant="h6"  sx={{
                                                        color: 'primary.main'
                                                    }}>CLI-{data?.id}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box
                                                    border
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                        alignItems: 'center'

                                                    }}
                                                >


                                                    {/*<Chip*/}
                                                    {/*  color={data?.status.key === 'PAID' ? 'success' :*/}
                                                    {/*    data?.status.key === 'PENDING' ? 'warning' :*/}
                                                    {/*      data?.status.key === 'SCHEDULED' ? 'warning' :*/}
                                                    {/*        data?.status.key === 'FINALIZED' ? 'success' :*/}
                                                    {/*          data?.status.key === 'PROCESSING' ? 'primary' :*/}
                                                    {/*            data?.status.key === 'ERROR' ? 'error' :*/}
                                                    {/*              data?.status.key === 'DUE' ? 'error' :*/}
                                                    {/*              data?.status.key === 'DEBIT' ? 'error' :*/}
                                                    {/*                data?.status.key === 'FAILED' ? 'error' :*/}
                                                    {/*                  data?.status.key === 'OPEN' && 'info'}*/}
                                                    {/*  size="small"*/}
                                                    {/*  variant="outlined"*/}
                                                    {/*  sx={{marginLeft:"1rem"}}*/}
                                                    {/*  label={data?.status.value}/>*/}

                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider/>

                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell >Código</TableCell>
                                                <TableCell>Empresa</TableCell>
                                                <TableCell>Data</TableCell>
                                                <TableCell width={150}>Valor</TableCell>
                                                <TableCell >Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { data?.rates.map(rate =>
                                                <TableRow>

                                                    <TableCell>
                                                        TRA-{rate.id}
                                                    </TableCell>
                                                    <TableCell>
                                                       {rate?.description}
                                                    </TableCell>
                                                    <TableCell >
                                                        {moment(`${rate.reference_date}`).format('DD/MM/YYYY')}

                                                    </TableCell>
                                                    <TableCell>
                                                        {Masks.money(rate.rate.toString())}
                                                    </TableCell>
                                                    <TableCell>
                                                        {rate.status_billing}
                                                    </TableCell>
                                                </TableRow> )}

                                            { data?.invoices.map(rate =>
                                                <TableRow>

                                                    <TableCell>
                                                        TRA-{rate.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {rate?.description}
                                                    </TableCell>
                                                    <TableCell >
                                                        {moment(`${rate.reference_date}`).format('DD/MM/YYYY')}

                                                    </TableCell>
                                                    <TableCell>
                                                        {Masks.money(rate.rate.toString())}
                                                    </TableCell>
                                                    <TableCell>
                                                        {rate.status_billing}
                                                    </TableCell>
                                                </TableRow> )}

                                            { data?.charges.map(rate =>
                                                <TableRow>

                                                    <TableCell>
                                                        TRA-{rate.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {rate?.description}
                                                    </TableCell>
                                                    <TableCell >
                                                        {moment(`${rate.reference_date}`).format('DD/MM/YYYY')}

                                                    </TableCell>
                                                    <TableCell>
                                                        {Masks.money(rate.rate.toString())}
                                                    </TableCell>
                                                    <TableCell>
                                                        {rate.status_billing}
                                                    </TableCell>
                                                </TableRow> )}





                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align={"right"}><Typography sx={{fontWeight:"bold",}} level={"h4"} color={data?.total < 0 && "error.main" }> Total:</Typography></TableCell>
                                                <TableCell><Typography sx={{fontWeight:"bold", fontSize:"1.3rem"}} level={"h4"} color={data?.total < 0 && "error.main" }> {Masks.money(data?.total.toString())}</Typography> </TableCell>
                                            </TableRow>
                                        </TableBody>

                                    </Table>

                                </>
                            }

                        </Card>

                    </Grid>

                    <Grid md={4}>
                        <Card >
                            <CardContent >
                                <Box sx={{display: "flex", alignItems: "center", padding: ".5rem 0 "}}>
                                    <ContactMailOutlined  sx={{
                                        color: 'primary.main'
                                    }} />
                                    <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                                        Dados do Cliente</Typography>
                                </Box>
                                <Divider/>

                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>{data?.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>CPF/CNPJ</TableCell>
                                            <TableCell>{data?.cpf_cnpj}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Referente</TableCell>
                                            <TableCell>{data?.month}/{data?.year}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Status</TableCell>
                                            <TableCell>{data?.status.value}</TableCell>
                                        </TableRow>
                                        {data?.reason && <TableRow>
                                            <TableCell>Razão</TableCell>
                                            <TableCell>{data?.reason}</TableCell>
                                        </TableRow> }
                                        <TableRow>
                                            <TableCell sx={{fontWeight: 'bold'}} >Total</TableCell>
                                            <TableCell sx={{fontWeight: 'bold', fontSize: '1.3rem'}}>{Masks.money(data?.total.toString())}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>

                        </Card>

                    </Grid>


                </Grid>}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isSaving}
                // onClick={() => {
                //   if (isSaving === false){
                //     setActiveBackdrop(false)
                //   }
                //
                // }}
            >
                <CircularProgress color="inherit" />

            </Backdrop>
        </>

    )
}

export default CompanyRateDetail
