import React from "react";
import {
  Divider,
  Grid,
  InputAdornment,
  Typography
} from "@mui/material";
import SimpleText from "../../../../../../components/Shared/SimpleText";
import {Percent} from "@mui/icons-material";

const CondoChargeConfig = ({
    condoData,
    setCondoData,
    error
  }) => {

  /*
    This component is related to the third segment of CondoUpdate form.
   */
  return (
    <>
      <Divider sx={{ mb: 3, mt: 5 }} textAlign={'center'}>
        <Typography variant={'h6'}>
          {'Configurações de Cobrança'}
        </Typography>
      </Divider>
      <Grid container spacing={2} rowSpacing={3}>

        <Grid item xs={12} md={6}>
          <SimpleText
            label={'Multa por Atraso (%)'}
            helperText={'Válido somente um valor de "Multa por atraso" - fixo ou %'}
            onlyNumbers
            state={condoData}
            setState={setCondoData}
            keyName={'fine_percent'}
            error={error}
            disabled={condoData?.fine_value?.toString()?.replace(/\D+/g, "") > 0}
            isLoading={!condoData}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Percent />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SimpleText
            label={'Multa por Atraso - Valor Fixo'}
            money
            state={condoData}
            setState={setCondoData}
            keyName={'fine_value'}
            disabled={condoData?.fine_percent > 0}
            error={error}
            isLoading={!condoData}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SimpleText
            label={'Acréscimo Mensal por Atraso (%)'}
            state={condoData}
            setState={setCondoData}
            keyName={'interest_percent'}
            error={error}
            helperText={'Válido somente um valor de "Acréscimo diário" - fixo ou %'}
            disabled={condoData?.interest_value?.toString()?.replace(/\D+/g, "") > 0}
            isLoading={!condoData}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Percent />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SimpleText
            label={'Acréscimo Diário por Atraso - Valor Fixo'}
            money
            state={condoData}
            setState={setCondoData}
            keyName={'interest_value'}
            error={error}
            disabled={condoData?.interest_percent > 0 }
            isLoading={!condoData}
          />
        </Grid>
      </Grid>
    </>

  )
}

export default CondoChargeConfig
